import { makeStyles } from '@material-ui/core/styles';
import {
    Centering, CreateMargin, FlexRow, CreatePadding, ClearMarginPadding,
} from '@theme_mixins';
import { WHITE } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    title: {
        ...CreateMargin(0, 0, 15, 0),
        ...Centering,
    },
    viewlistBrand: {
        ...FlexRow,
        ...ClearMarginPadding,
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        ...CreatePadding(0),
        ...CreateMargin(0),
    },
    listBrand: {
        width: 102,
        height: 102,
        cursor: 'pointer',
        borderRadius: 4,
        listStyle: 'none',
        backgroundColor: WHITE,
        alignItems: 'center',
        ...CreateMargin(0, 5, 10, 5),
        boxShadow: '0 1px 2.5px rgb(0 0 0 / 38%)',
        [theme.breakpoints.down(480)]: {
            width: 77,
            height: 77,
            ...CreateMargin(0, 5, 10, 5),
        },
    },
    aL: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        margin: '0 auto',
        padding: 8,
    },
    imgBrand: {
        width: '100%',
        objectFit: 'contain',
        maxWidth: '100%',
        height: '100%',
    },
    allContainer: {
        margin: '0 auto',
        width: 450,
        [theme.breakpoints.down(480)]: {
            width: 350,
        },
    },
    wrapperBrands: {
        marginBottom: '40px',
    },
    viewSkeleton: {
        width: '100%',
        flexWrap: 'wrap',
        ...FlexRow,
    },
    itemSkeleton: {
        width: '20%',
        height: 96,
        ...CreateMargin(10, 5, 10, 10),
    },
}));

export default useStyles;
