import GridList from '@common_gridlist';
import useStyles from '@core_modules/brands/pages/default/components/style';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonBrands = () => {
    const styles = useStyles();
    const Item = () => (
        <>
            <div className={styles.viewSkeleton}>
                <Skeleton variant="rect" className={styles.itemSkeleton} />
                <Skeleton variant="rect" className={styles.itemSkeleton} />
                <Skeleton variant="rect" className={styles.itemSkeleton} />
                <Skeleton variant="rect" className={styles.itemSkeleton} />
            </div>
        </>
    );
    return (
        <>
            {/* <Typography
                align="center"
                letter="uppercase"
                type="bold"
                variant="span"
                className={styles.title}
            >
                {t('brands:featuredBrands')}
            </Typography> */}
            {/* <Typography
                align="center"
                letter="uppercase"
                type="bold"
                variant="span"
                className={styles.title}
            >
                {t('brands:allBrands')}
            </Typography> */}
            <br />
            <GridList data={[1, 2, 3, 4]} ItemComponent={Item} gridItemProps={{ xs: 12, sm: 12, md: 12 }} />
        </>
    );
};

export default SkeletonBrands;
