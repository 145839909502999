/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import GridList from '@common_gridlist';
import Typography from '@common_typography';
import { HOST } from '@config';
import useStyles from '@core_modules/brands/pages/default/components/style';
import { getAppEnv } from '@helpers/env';
import { useRouter } from 'next/router';

const Item = (props) => {
    const styles = useStyles();
    const router = useRouter();
    const { children, isLogin } = props;
    const redirect = (val) => (isLogin ? val : '/customer/account/login');
    return (
        <div className={styles.allContainer}>
            <Typography align="center" letter="uppercase" type="bold" variant="span">
                {/* {group} */}
                <ul className={styles.viewlistBrand}>
                    {children.map((val, idx) => (
                        <>
                            {val.is_active === 1 ? (
                                <li key={idx} className={styles.listBrand}>
                                    {val.attribute_id && val.category_url !== null ? (
                                        <a
                                            className={styles.aL}
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                smartech('dispatch', 'br_home_brand', {
                                                    name: `${val.name}`,
                                                });
                                                router.push(redirect(val.category_url.replace(HOST[getAppEnv()], '')));
                                            }}
                                        >
                                            <img className={styles.imgBrand} src={val.logo} alt="brand logo" />
                                        </a>
                                    ) : val.attribute_id && val.category_url === null ? (
                                        <a
                                            className={styles.aL}
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                smartech('dispatch', 'br_home_brand', {
                                                    name: `${val.name}`,
                                                });
                                                router.push(`/catalogsearch/advanced/result?brand[]=${val.attribute_id}`);
                                            }}
                                        >
                                            <img className={styles.imgBrand} src={val.logo} alt="brand logo" />
                                        </a>
                                    ) : (
                                        val.name
                                    )}
                                </li>
                            ) : null}
                        </>
                    ))}
                </ul>
            </Typography>
        </div>
    );
};

const AllBrands = (props) => {
    const { all, isLogin } = props;
    return (
        <>
            {/* <Typography
                align="center"
                letter="uppercase"
                type="bold"
                variant="span"
                className={styles.title}
            >
                {t('brands:allBrands')}
            </Typography> */}
            <GridList data={all} isLogin={isLogin} ItemComponent={Item} gridItemProps={{ xs: 12, sm: 12, md: 12 }} />
        </>
    );
};

export default AllBrands;
